var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import memoizeOne from 'memoize-one';
import shallowEqual from 'shallowequal';
import SplitContext from './SplitContext';
import { getControlTreatmentsWithConfig, WARN_ST_NO_CLIENT } from './constants';
function argsAreEqual(newArgs, lastArgs) {
    return newArgs[0] === lastArgs[0] && // client
        newArgs[1] === lastArgs[1] && // lastUpdate
        shallowEqual(newArgs[2], lastArgs[2]) && // names
        shallowEqual(newArgs[3], lastArgs[3]); // attributes
}
function evaluateSplits(client, lastUpdate, names, attributes) {
    return client.getTreatmentsWithConfig(names, attributes);
}
/**
 * SplitTreatments accepts a list of split names and optional attributes. It access the client at SplitContext to
 * call 'client.getTreatmentsWithConfig()' method, and passes the returned treatments to a child as a function.
 *
 * @see {@link https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#get-treatments-with-configurations}
 */
var SplitTreatments = /** @class */ (function (_super) {
    __extends(SplitTreatments, _super);
    function SplitTreatments() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Attaching a memoized `client.getTreatmentsWithConfig` function to the component instance, to avoid duplicated impressions because
        // the function result is the same given the same `client` instance, `lastUpdate` timestamp, and list of split `names` and `attributes`.
        _this.evaluateSplits = memoizeOne(evaluateSplits, argsAreEqual);
        return _this;
    }
    SplitTreatments.prototype.render = function () {
        var _this = this;
        var _a = this.props, names = _a.names, children = _a.children, attributes = _a.attributes;
        return (React.createElement(SplitContext.Consumer, null, function (splitContext) {
            var client = splitContext.client, isReady = splitContext.isReady, isReadyFromCache = splitContext.isReadyFromCache, isDestroyed = splitContext.isDestroyed, lastUpdate = splitContext.lastUpdate;
            var treatments;
            var isOperational = !isDestroyed && (isReady || isReadyFromCache);
            if (client && isOperational) {
                treatments = _this.evaluateSplits(client, lastUpdate, names, attributes);
            }
            else {
                treatments = getControlTreatmentsWithConfig(names);
                if (!client) {
                    _this.logWarning = true;
                }
            }
            // SplitTreatments only accepts a function as a child, not a React Element (JSX)
            return children(__assign(__assign({}, splitContext), { treatments: treatments }));
        }));
    };
    SplitTreatments.prototype.componentDidMount = function () {
        if (this.logWarning) {
            console.log(WARN_ST_NO_CLIENT);
        }
    };
    return SplitTreatments;
}(React.Component));
export default SplitTreatments;
