import { validateSplits } from './utils';
// The string below is a marker and will be replaced by the real version number. DO NOT CHANGE
export var VERSION = 'react-' + '1.3.1-rc.1';
// Treatments
export var ON = 'on';
export var OFF = 'off';
export var CONTROL = 'control'; // SplitIO default value
export var CONTROL_WITH_CONFIG = {
    treatment: 'control',
    config: null,
};
export var getControlTreatmentsWithConfig = function (splitNames) {
    // validate split Names
    var validatedSplitNames = validateSplits(splitNames);
    // return empty object if the returned value is false
    if (!validatedSplitNames)
        return {};
    // return control treatments for each validated split name
    return validatedSplitNames.reduce(function (pValue, cValue) {
        pValue[cValue] = CONTROL_WITH_CONFIG;
        return pValue;
    }, {});
};
// Warning and error messages
export var WARN_SF_CONFIG_AND_FACTORY = '[WARN] Both a config and factory props were provided to SplitFactory. Config prop will be ignored.';
export var ERROR_SF_NO_CONFIG_AND_FACTORY = '[ERROR] SplitFactory must receive either a Split config or a Split factory as props.';
export var ERROR_SC_NO_FACTORY = '[ERROR] SplitClient does not have access to a Split factory. This is because it is not inside the scope of a SplitFactory component or SplitFactory was not properly instantiated.';
export var WARN_ST_NO_CLIENT = '[WARN] SplitTreatments does not have access to a Split client. This is because it is not inside the scope of a SplitFactory component or SplitFactory was not properly instantiated.';
var ERROR_NO_USECONTEXT = '[ERROR] Check your React version since `useContext` hook is not available. Split hooks require version 16.8.0+ of React.';
export var ERROR_UC_NO_USECONTEXT = ERROR_NO_USECONTEXT + ' Returning null from `useClient` hook.';
export var ERROR_UM_NO_USECONTEXT = ERROR_NO_USECONTEXT + ' Returning null from `useManager` hook.';
export var ERROR_UT_NO_USECONTEXT = ERROR_NO_USECONTEXT + ' Returning control treatments from `useTreatments` hook.';
export var ERROR_UTRACK_NO_USECONTEXT = ERROR_NO_USECONTEXT + ' Returning a no-op function from `useTrack` hook.';
export var EXCEPTION_NO_REACT_OR_CREATECONTEXT = 'React library is not available or its version is not supported. Check that it is properly installed or imported. Split SDK requires version 16.3.0+ of React.';
