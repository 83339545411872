var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SplitTreatments from './SplitTreatments';
/**
 * High-Order Component for SplitTreatments.
 * The wrapped component receives all the props of the container,
 * along with the passed props from SplitTreatments (see ISplitTreatmentsChildProps).
 *
 * @param names list of Split names
 * @param attributes An object of type Attributes used to evaluate the splits.
 */
function withSplitTreatments(names, attributes) {
    return function withSplitTreatmentsHoc(WrappedComponent) {
        return function (props) {
            return (React.createElement(SplitTreatments, { names: names, attributes: attributes }, function (splitProps) {
                return (React.createElement(WrappedComponent, __assign({}, props, splitProps)));
            }));
        };
    };
}
export default withSplitTreatments;
